<template>
  <div>
      <addNewDepartment
    v-if="isLoading === false"
    :is-add-new-user-sidebar-active.sync="isAddNewDepartmentSidebarActive"
    :role-options="roleOptions"
    :plan-options="planOptions"
    @refetch-data="refetchData"
    :departmentList="departmentList"
 
  />
 <!-- {{ departmentList }} -->
 <!-- {{ selectedDepartment }} -->
  <b-row>
      <b-col
      md="12"
      sm="4"
      class="my-1 text-right"
    > 
       <b-button
 v-if="userRole.generalRole === 'Admin'"
              variant="primary"
              @click="isAddNewDepartmentSidebarActive = true"
            >
              <span class="text-nowrap">Yeni Departman Ekle</span>
            </b-button>
          </b-col>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Liste adeti</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Departman"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
      <!-- {{ selectedDepartment }} -->
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="selectedDepartment"
            :options="sortOptions"
            value="value"
            class="w-75"
          >
            <template v-slot:first>
            
            </template>
          </b-form-select>
     
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Ara"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Ad ile arama yapın..."
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Temizle
            </b-button>
          </b-input-group-append>
        </b-input-group>
       
      </b-form-group>
    </b-col>
<!-- dd{{ departmentList }} -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="departmentList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered(departmentList)"
        @row-clicked="rowClicked"
       
      >
      <!-- <template #cell(user.image)="data">
<b-avatar :src="`${baseUrl}+${data.value}`" />
</template> -->

        <template #cell(department)="data">
          <b-badge variant="primary">
      <div v-if="!data.item.parent"> 
            {{ !data.item.parent ?'Ana Departman':departments.find(item => data.item.parent === item.value).text+'`ın Alt Departmanı' }}
  

</div>
<div v-else>
  {{ departments.find(item => data.item.parent === item.departmentId).name+'`ın Alt Departmanı' }}

</div>
          </b-badge>
        </template>
         <!-- Column: Action -->
         <template #cell(düzenle)="data">
         
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
            
              <span @click="rowClicked(data.item)">Düzenle</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
      </span>
  </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</div>
</template>

<script>
import {getUserData} from '@/auth/utils'
import { BASE_URL } from '@/helpers/Url/BaseUrl'
import { mapActions } from 'vuex';

import { Api } from '@/helpers/Api/apiConfig'
import addNewDepartment from '@/layouts/components/AddNewDepartment.vue'



import {
  BDropdown, BDropdownItem, BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
//   beforeRouteEnter(to, from, next) {
//   const userData = localStorage.getItem('userData')
//   alert(userData)
//   if (userData.isAdmin) {
//     next()
//   } else {
//     next({ name: 'error-404' })
//   }
// },
  
  components: {
   
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdownItem,
    BDropdown,
    addNewDepartment
    
  },
  data() {
    return {
      userRole: getUserData(),
      departments: [],
      isLoading: false,
      departmentList: [],
    roleOptions: [
      { text: 'Admin', value: 1 },
    ],
    planOptions: [
      { text: 'Basic', value: 1 },
    ],
    refetchData:'',
      isAddNewDepartmentSidebarActive: false,
      baseUrl: BASE_URL,
      selectedDepartment: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'value', label: 'Id',
        // },
        {
          key: 'text', label: 'Adı',
        },
         { key: 'department', label: 'Kategori Tipi', sortable: true },
        // { key: 'user.lastname', label: 'Soyad', sortable: true },

        // { key: 'efficiencyInformation.efficient', label: 'Verim', sortable: true },

        // { key: 'user.email', label: 'email', sortable: true },
        // { key: 'user.phone', label: 'phone', sortable: true },
        // { key: 'isManager', label: 'Yetki', sortable: true },
        //  { key: 'department.name', label: 'Departman', sortable: true },
         'Düzenle',
      //   { key: 'department.name', label: 'experience', sortable: true },
        

      // //   'user.email',
      // //   'user.email',
      // //   'user.email',
      // //   'user.email',
      // //   'user.email',
      // //   'user.email',
      //   { key: 'department.name', label: 'Status', sortable: true },
      ],
      items: [
      
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  watch: {
    selectedDepartment() {
      this.getDepartmentUsers(this.selectedDepartment)
    },
    departmentList(val){
      this.departmentList = val
    }
  },
  computed: {
    ...mapActions('userData', 'updateUserData'),

    sortOptions() {
      
      // Create an options list from our fields
      return  ([{text: 'Tüm Departmanlar',value:null },{ text: 'Ana Departmanlar', value: 0},{text: 'Alt Departmanlar',value:1 }])
    },
        
       // .filter(f => f.sortable)
   
          
    //   // Create an options list from our fields
    //   return this.departmentList
    //    // .filter(f => f.sortable)
    //     .map(f => ({ text: f.text, value: f.value,
    //       parent: f.parent,
    //       }))
    // },
    
  },
 async mounted() {
    

      await this.getDepartmentsDetail()
   // this.getDepartmentUsers(this.selectedDepartment)

    // Set the initial number of items
  

  },
  methods: {
    rowClicked(item){
      // let userData=item.user

       console.log('event depart',item);
   
      //  this.$store.commit('settingsUser/setUserData',userData)
     
        this.$router.push({name:'department-edit',params:{id:item.value}})

      
      
    },
      async getDepartmentsDetail() {
    this.isLoading = true;
    await Api("GET", "Department").then((res) => {
      this.departments = res.data.data.items;
      this.departmentList = this.departments.map((item) => {
        return {
          text: item.name,
          value: item.departmentId,
          parent: item.parentId,
         
          //isActive:false
        };
      });
      // this.departmentList.unshift({
      //   text: 'Tüm Departmanlar',
      //   value: null,
     
      // }) 
      // this.$emit('departmentList',this.departmentList)

      this.totalRows = this.departmentList.length
      console.log( 'userrdepartments',this.departments);
      this.isLoading = false;
    });
  },
      async getDepartmentUsers(departmentId){
        this.isLoading = true;
        console.log('gelen seelected value', departmentId,'departments',this.departments);
        this.departmentList =[]
       
      if(departmentId ==0  ){
        this.departmentList = this.departments.filter ((item) => item.parentId === "").map((item) => {
     
     return {
     text: item.name,
     value: item.departmentId,
     parent: item.parentId,
    
     //isActive:false
   };
   
 });
   this.totalRows = this.departmentList.length

   console.log('çıkan departmnet list',this.departmentList,departmentId);
   this.isLoading = false;
      }else if(departmentId ==1 ){
        this.departmentList =[]
        this.departmentList = this.departments.filter ((item) => item.parentId !== '').map((item) => {
     
     return {
      text: item.name,
     value: item.departmentId,
     parent: item.parentId,
    
     //isActive:false
   };
   
 });
   this.totalRows = this.departmentList.length

   console.log('çıkan departmnet list',this.departmentList,departmentId);
   this.isLoading = false;
      }else if(departmentId ==null ){
        this.departmentList = this.departments.map((item) => {
        return {
          text: item.name,
          value: item.departmentId,
     parent: item.parentId,
         
          //isActive:false
        };
      });
   this.totalRows = this.departmentList.length

   console.log('çıkan departmnet list',this.departmentList,departmentId);
   this.isLoading = false;
      }
      
      
  
  

       
      },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)

      console.log('info',item, index, button);
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
 